import React from "react";
import { Link } from "react-scroll";
import "../styles/nav.css";

function Navbar() {
  // function scrollTo() {
  //   scroller.scrollTo('scroll-to-element', {
  //     duration: 800,
  //     delay: 0,
  //     smooth: 'easeInOutQuart'
  //   })
  // }

  return (
    // <nav className="navBar frost">
    <nav className="navBar">
      <div className="">
        <div>
          <ul className="navbarUl">
            {/* <li className="navItem">
              <a href="#home">
                  Home
              </a>
            </li> */}
            <li className="navItem">
              <Link
                activeClass=""
                className="navLink"
                to="about"
                smooth={true}
                duration={500}
                offset={-200}
              >
                About
              </Link>
              {/* <a href="#about">
                  About
              </a> */}
            </li>
            <li className="navItem">
              <Link
                activeClass=""
                className="navLink"
                to="personal"
                smooth={true}
                duration={1000}
              >
                Projects
              </Link>
              {/* <a>
                  Projects
              
              </a> */}
            </li>
            <li className="navItem">
              <Link
                activeClass=""
                className="navLink"
                to="pastWork"
                smooth={true}
                duration={1200}
              >
                Past Work
              </Link>
              {/* <a>
                  Projects
              </a> */}
            </li>
            <li className="navItem">
              {/* <a href="#contact">
                  Contact
              </a> */}
              <Link
                activeClass=""
                className="navLink"
                to="contact"
                smooth={true}
                duration={1500}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
