import React, { useEffect, useRef, useState } from "react";
import "../styles/contactFloater.css";
import useOnScreen from "./useOnScreen";
import { Parallax } from "react-scroll-parallax";

function ContactFloater(props) {
  const [onScreenChange, isVisible] = useOnScreen({
    root: null,
    rootMargine: "0px",
    threshold: 0.2,
    undoOnHidden: true,
  });
  const [attributesOnScreen, setAtt] = useState(false);

  function toggleAtt() {
    setAtt(!attributesOnScreen);
  }

  return (
    <>
      <div className="contactContainer">
        <div
          className={isVisible ? "floatGone float floatTop" : "float floatTop"}
        >
          <img className="contactIcon" src={require("../assets/contact.png")} />
          <h5 className="firstH4">Contact Me!</h5>
          <h4
            onClick={() =>
              window.open("https://www.linkedin.com/in/ramin-kahidi-bb84b921b/")
            }
          >
            <div className="innerContact">LinkedIn</div>
          </h4>
          <h4 onClick={() => window.open("https://github.com/RaminKahidi")}>
            <div className="innerContact">Github</div>
          </h4>
          {/* <h4 onClick={() => window.open("mailto:hello@raminkahidi.com")}> */}
          <h4 onClick={() => window.open("mailto:raminkah@gmail.com")}>
            <div className="innerContact">Email</div>
          </h4>
        </div>
        {/* <Parallax translateY={[1 , 0]}> */}
        <div className="contactTopCurve">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
        <div className="floatBottom float">
          <h5 className="firstH4">Contact Me!</h5>
          <h4
            onClick={() =>
              window.open("https://www.linkedin.com/in/ramin-kahidi-bb84b921b/")
            }
          >
            <div className="innerContact">LinkedIn</div>
          </h4>
          <h4 onClick={() => window.open("https://github.com/RaminKahidi")}>
            <div className="innerContact">Github</div>
          </h4>
          {/* <h4 onClick={() => window.open("mailto:hello@raminkahidi.com")}> */}
          <h4 onClick={() => window.open("mailto:raminkah@gmail.com")}>
            <div className="innerContact">Email</div>
          </h4>
          <h4
            className="attributionsContainer"
            onClick={() => setAtt(!attributesOnScreen)}
          >
            <div
              className={
                attributesOnScreen
                  ? "attributionsPopup attributionsPopupActive"
                  : "attributionsPopup"
              }
            >
              The{" "}
              <a href="https://sketchfab.com/3d-models/porsche-911-rauh-welt-0e1f5e1c0ddc4c3f88b8484461146a79">
                Porsche 911 RAUH-Welt{" "}
              </a>
              Model was made by{" "}
              <a href="https://sketchfab.com/autoNgraphic">autoNgraphic</a>{" "}
              licensed under{" "}
              <a href="http://creativecommons.org/licenses/by/4.0/">
                CC-BY-4.0
              </a>
            </div>
            <div className="attributions">Attributions</div>
          </h4>
        </div>
        {/* </Parallax> */}
      </div>
      <div id="contact">
        <div ref={onScreenChange} className="detectBottom"></div>
      </div>
    </>
  );
}

export default ContactFloater;
