import useOnScreen from "./useOnScreen";

function LeftInText(props) {
  const [onScreenChange, isVisible] = useOnScreen({
    root: null,
    rootMargine: "0px",
    threshold: 0.7,
  });
  return (
    <div
      ref={onScreenChange}
      className={
        isVisible ? "textTransform onScreen" : "textTransform leftOffScreen"
      }
    >
      {props.children}
    </div>
  );
}

export default LeftInText;
