import { useEffect, useRef, useState } from "react";

const useOnScreen = (options) => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const callbackFunction = (entries) => {
    // console.log("call back called");
    const [entry] = entries;
    if (isVisible !== true) {
      // console.log("x is visiable");
      setIsVisible(entry.isIntersecting);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      if (containerRef.current) {
        if (!options.undoOnHidden) {
          observer.unobserve(containerRef.current);
        } else {
          // observer.observe(containerRef.current);
        }
      }
    };
  }, [containerRef, options]);

  return [containerRef, isVisible];
};

export default useOnScreen;
// export default function useOnScreen(ref) {

//     const [isIntersecting, setIntersecting] = useState(false)

//     const observer = new IntersectionObserver(
//       ([entry]) => setIntersecting(entry.isIntersecting)
//     )

//     useEffect(() => {
//       observer.observe(ref.current)
//       // Remove the observer as soon as the component is unmounted
//       return () => { observer.disconnect() }
//     }, [])

//     return isIntersecting
// }
