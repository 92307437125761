import React, { useEffect, useRef } from "react";
import ProjectTemplate from "../ProjectCard/ProjectCard";
import "../../styles/home.css";
import "./PastWork.css";

import rbc from "../../assets/rbc.jpg";
import userful from "../../assets/Userful.jpg";
import igem from "../../assets/IgemTeam.jpg";

import { Parallax } from "react-scroll-parallax";
import LeftInText from "../LeftInText";
import useOnScreen from "../useOnScreen";

// function importAll(r) {
//   let images = {};
//   r.keys().map((item) => {
//       images[item.replace("./", "")] = r(item);
//   });
//   return images;
// }
// const importedImages = importAll(
//   require.context("../assets", false, /\.(png|jpe?g|JPE?G|svg)$/)
// );

function PastWork() {
  // const containerRef = useRef(null)
  // const callbackFunction = (entries) => {
  //   const [entry ] = entries
  //   console.log("fUkc");
  // }
  // const options = {
  //   root: null,
  //   rootMargin: "0px",
  //   threshold: 1.0
  // }
  // useEffect(() => {
  //   const observer = new IntersectionObserver(callbackFunction, options)
  //   if (containerRef.current) observer.observe(containerRef.current)

  //   return () => {
  //     if(containerRef.current) observer.unobserve(containerRef.current)
  //   }
  // }, [containerRef, options])
  const [onScreenChange, isVisible] = useOnScreen({
    root: null,
    rootMargine: "0px",
    threshold: 0.7,
  });

  return (
    <div id="pastWork">
      {/* <Parallax speed={-5}> */}
      <section id="projects">
        <div className="">
          <div className="">
            {/* <div className="col-lg-5">
              <h1 className="sectionTitle">Past work</h1>
            </div> */}

            <div className="personalTitlePanel">
              <LeftInText>
                <h1 className="textPanel">Past work</h1>
              </LeftInText>
              <div className="carPanel">
                <Parallax translateY={["50px", "-100px"]}>
                  <img
                    ref={onScreenChange}
                    className={
                      isVisible
                        ? "imgNoDrawBorders imgDrawBorders"
                        : "imgNoDrawBorders"
                    }
                    src={require("../../assets/doggy.jpeg")}
                  />
                </Parallax>
              </div>
            </div>
            <div className="card-container">
              {/* <h2 className="researchTitle">Research</h2> */}
              <div className="allProjectsContainer inner-card-container">
                <ProjectTemplate
                  title="RBC Amplify - Dr.Bill"
                  link="https://jobs.rbc.com/ca/en/amplify"
                  description="As an RBC Amplify Developer, my team and I developed a voice-enabled automation tool prototype, aiming to simplify medical billing for thousands of Canadian physicians."
                  imgTitle={rbc}
                />
                <ProjectTemplate
                  title="Userful Corporation"
                  link="https://www.userful.com/"
                  description="Over the summer of 2022, I had the pleasure of working with the Userful Software Engineering Team as a Software Development Intern. My projects involved building front-end web apps with React and Redux. "
                  imgTitle={userful}
                />
                <ProjectTemplate
                  title="Neocycle"
                  link="https://2021.igem.org/Team:Calgary"
                  description="As a member of the iGEM Calgary team, I had the pleasure of competing in an international genetic engineering competition, developing a system for recycling E-waste in a more environmentally friendly way."
                  imgTitle={igem}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* </Parallax> */}
    </div>
  );
}

export default PastWork;
