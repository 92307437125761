import "./App.css";
import "./styles/main.css";
import "./styles/home.css";

import React, { useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { useCookies, CookiesProvider } from "react-cookie";

import Intro from "./components/Intro/Intro";
import About from "./components/About";
import Navbar from "./components/Navbar";
import PastWork from "./components/PastWork/PastWork";
// import TopCanvas from "./components/Canvas";
import Personal from "./components/Personal/PersonalNew";
import Contact from "./components/ContactFloater";
// import Footer from "./components/Footer";

// import gameImport from "../public/MisadventuresofLanE-1.0-web/index.html";

// const cursor = document.querySelector('#cursor');

//   document.addEventListener('mousemove', e => {
//       cursor.setAttribute("style", "top: "+(e.pageY - 10)+"px; left: "+(e.pageX - 10)+"px;")
//   })

// document.addEventListener('click', () => {
//     cursor.classList.add("expand");

//     setTimeout(() => {
//         cursor.classList.remove("expand");
//     }, 500)
// })

function App() {
  // useCookie docs
  const [cookies, setCookie] = useCookies([""]);
  const firstUpdate = useRef(true);

  // https://www.youtube.com/watch?v=nvhNp2A3hxI

  const tl = useRef();
  const mainContainer = useRef();
  const q = gsap.utils.selector(mainContainer);

  // **** useGSAP ****
  useGSAP(() => {
    if (cookies.visited) {
      tl.current = gsap
        .timeline({ defaults: { ease: "power1.out" } })
        .fromTo(
          q(".aboutMe"),
          { x: "-15%", opacity: 0 },
          { x: "0%", opacity: 1, duration: 0.5, ease: "power1.out" }
        )
        .fromTo(
          q(".raminPhoto"),
          { x: "15%", opacity: 0 },
          { x: "0%", opacity: 1, duration: 0.5, ease: "power1.out" },
          "-=0.5"
        )
        .fromTo(
          q(".floatTop"),
          { y: "140%" },
          { y: "0%", duration: 0.9, ease: "elastic.out" }
        );
    } else {
      // If the cookie doesn't exist, play the animation and set the cookie
      tl.current = gsap
        .timeline({ defaults: { ease: "power1.out" } })
        .to(q(".hidingText"), { y: "0%", duration: 1, stagger: 0.25 })
        .to(q(".slider"), { y: "-100%", duration: 1.5, delay: 0.5 })
        .to(q(".intro"), { y: "-100%", duration: 1 }, "-=1")
        .fromTo(q(".nav"), { opacity: 0 }, { opacity: 1, duration: 0.5 })
        .fromTo(
          q(".floatTop"),
          { y: "140%" },
          { y: "0%", duration: 0.9, ease: "elastic.out" },
          "-=1"
        )
        .fromTo(
          q(".aboutMe"),
          { x: "-15%", opacity: 0 },
          { x: "0%", opacity: 1, duration: 0.5, ease: "power1.out" },
          "-=2"
        )
        .fromTo(
          q(".raminPhoto"),
          { x: "15%", opacity: 0 },
          { x: "0%", opacity: 1, duration: 0.5, ease: "power1.out" },
          "-=2"
        );
      setCookie("visited", true, { path: "/", maxAge: 60 * 60 * 24 * 7 });
    }
    // tl.fromTo(big_text.current, { opacity: 0 }, { opacity: 1, duration: 1 }, "-=1");
  }, []);

  return (
    // return one day and make scroll bar a lil cooler https://github.com/malte-wessel/react-custom-scrollbars/blob/master/docs/customization.md
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Ramin Kahidi</title>
          <meta
            name="description"
            content="I'm Ramin Kahidi, and this is my personal portfolio displaying some of my past work."
          />
          <meta name="keywords" content="Ramin Kahidi, Portfolio, Past Work" />
        </Helmet>
        {/* <TopCanvas styles={{position: "absolute !important" }}/> */}
        <main ref={mainContainer}>
          <div className="">
            <CookiesProvider defaultSetOptions={{ path: "/" }}>
              {!cookies.visited && (
                <div className="intro">
                  <div className="intro-text">
                    <h1 className="hide">
                      <span className="hidingText">Hi!</span>
                    </h1>
                    <h1 className="hide">
                      <span className="hidingText">Glad you stopped by</span>
                    </h1>
                  </div>
                </div>
              )}
            </CookiesProvider>
            <div className="slider"></div>
            <div className="nav frost">
              <Navbar />
            </div>
          </div>
          <div className="intro-background">
            <Intro />
          </div>

          <About />
          <Personal />
          <PastWork />
          <Contact />
        </main>
      </div>
    </HelmetProvider>
  );
}

export default App;
