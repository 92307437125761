import React from "react";
import "./intro.css";
import ramin from "../../assets/ramin.JPG";
import p1 from "../../assets/proteins/p1.png";
import p2 from "../../assets/proteins/p2.png";
import p3 from "../../assets/proteins/p3.png";
import p4 from "../../assets/proteins/p4.png";
import p5 from "../../assets/proteins/p5.png";
import p6 from "../../assets/proteins/p6.png";
// import raminBackground from "../../assets/raminBackground.png";
// import raminForeground from "../../assets/raminForeground.png";
import { Parallax } from "react-scroll-parallax";

function Intro() {
  return (
    <section id="intro">
      <Parallax
        id="intro-container"
        className="frost"
        translateY={["0px", "120px"]}
      >
        {/* <div id="" className=""> */}
        <div>
          <h1 className="aboutMe">Hi! I'm Ramin. </h1>
          <p className="aboutMe introText">
            I like to learn about different disciplines to solve problems
            creatively.
          </p>
        </div>
        {/* <img className="raminPhoto" src={ramin} alt="" /> */}
        <div className="raminPhoto">
          <img src={ramin} alt="" />
          {/* <Parallax className="raminOverlap" translateY={(-8, -3)}> */}
          {/* <img src={raminForeground} alt="" /> */}
          {/* </Parallax> */}
        </div>
        {/* </div> */}
      </Parallax>
      <div className="floatingImageContainers">
        {/* <Parallax
          className="floatingImages"
          translateY={["50vh", "10vh"]}
          style={{ left: "20vw", width: "30vw" }}
          slowerScrollRate={true}
        >
          <img className="" src={p1} alt="" />
        </Parallax>
        <Parallax
          className="floatingImages"
          translateY={["100vh", "50vh"]}
          slowerScrollRate={true}
          style={{ right: "0px", width: "10vw" }}
        >
          <img className="" src={p5} alt="" />
        </Parallax>

        <Parallax
          className="floatingImages"
          translateY={["60vh", "40vh"]}
          style={{ left: "5vw", width: "5vw" }}
          slowerScrollRate={true}
        >
          <img className="" src={p3} alt="" />
        </Parallax> */}
      </div>
    </section>
  );
}

export default Intro;
