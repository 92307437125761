import React, { useRef } from "react";
import LeftInText from "./LeftInText";

import { gsap } from "gsap";
// import { Parallax } from "react-scroll-parallax";

function About() {
  const mainContainer = useRef();
  const q = gsap.utils.selector(mainContainer);

  return (
    <>
      <div id="aboutSecContainer" className="whiteBackground">
        <div className="wave">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
        <section ref={mainContainer} className="aboutDiv" id="about">
          {/* <Parallax translateY={[0, -20]}> */}
          <div id="aboutContainer">
            <LeftInText>
              <h1 className="aboutTitle">About Me</h1>
            </LeftInText>
            <LeftInText>
              <p className="aboutText">
                I'm a student at the University of Calgary. I enjoy bringing
                together knowledge from different disciplines to tackle complex
                challenges.
              </p>
            </LeftInText>
            <LeftInText>
              <p className="aboutText">
                In the past, I've worked as a Software Developer in the RBC
                Amplify program, and on the iGEM Calgary Team as an
                undergraduate researcher. My experiences span from creating
                lively user interfaces through web and game development, to
                understanding biological systems through data science and
                machine learning.
              </p>
            </LeftInText>
            <LeftInText>
              <p className="aboutText">
                In my free time I read manga, fall off my penny board, and dream
                up vacation plans that I'll never bring to life.
              </p>
            </LeftInText>
          </div>
          {/* </Parallax> */}
        </section>
        <div className="whiteBackground fillerBox"></div>
      </div>
    </>
  );
}

export default About;
