import useOnScreen from "./useOnScreen";
import "./Personal/personal.css";

function DivBorder(props) {
  const [onScreenChange, isVisible] = useOnScreen({
    root: null,
    rootMargine: "0px",
    threshold: 0.7,
  });
  return (
    <>
      <hr
        ref={onScreenChange}
        className={isVisible ? "hLTransform borders" : "hLTransform noBorders"}
      ></hr>
      {props.children}
    </>
  );
}

export default DivBorder;
